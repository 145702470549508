import React from 'react';
import dynamic from 'next/dynamic';

const Redirect = dynamic(() => import('../components/Redirect'), {
  ssr: false,
});

const Home = () => {
  return (
    <>
      <Redirect to="https://lifetales.com" />
      <div />
    </>
  );
};

export default Home;
